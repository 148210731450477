import React from 'react';

import logo from './logo.svg';
import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './NavBar';
import CategoryDetail from './CategoryDetail';
import Footer from './Footer';
import PostDetail from './PostDetail';

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:slug" element={<CategoryDetail />} />
          <Route path="/:categorySlug/:slug" element={<PostDetail />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
