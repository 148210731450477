import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import MainMenu from './components/MainMenu';
import logo from './logo.png';

const NavBar = () => {
  return (
    <header className="header">
      <nav className="nav">
        <div className='container'>
          <Link to="/" className="logo">
            <img src={logo} alt="SmartMoney Logo" className="logo-image" width={150} />
          </Link>
        </div>
      </nav>
      <div className="nav-links">
        <div className='container'>
            <MainMenu />
        </div>
      </div>
    </header>
  );
};

export default NavBar;
