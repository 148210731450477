import React, { useEffect, useState } from 'react';
import './FeaturedPosts.css';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const FeaturedPosts = () => {
  const [featuredPost, setFeaturedPost] = useState(null);
  const [featuredImage, setFeaturedImage] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [categorySlug, setCategorySlug] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/posts?categories=1&per_page=1`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          const post = data[0];
          setFeaturedPost(post);

          if (post.categories && post.categories.length > 0) {
            fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/categories/${post.categories[0]}`)
              .then(response => response.json())
              .then(categoryData => {
                setCategoryName(categoryData.name);
                setCategorySlug(categoryData.slug);
              });
          }

          const postDate = new Date(post.date);
          const timeAgo = formatDateToAgo(postDate);
          setFormattedDate(timeAgo);

          if (post.featured_media) {
            fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/media/${post.featured_media}`)
              .then(response => response.json())
              .then(mediaData => {
                setFeaturedImage(mediaData.source_url);
              });
          }
        }
        setLoading(false); 
      })
      .catch(error => {
        console.error('Error fetching featured post:', error);
        setLoading(false);
      });
  }, []);

  const formatDateToAgo = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) return `${interval} years ago`;
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} months ago`;
    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} days ago`;
    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} hours ago`;
    interval = Math.floor(seconds / 60);
    if (interval > 1) return `${interval} minutes ago`;
    return `${seconds} seconds ago`;
  };

  return (
    <div className='featuredPost'>
      {loading ? (
        <div className='innerWrapper'>
          <div className='image'>
            <Skeleton height={400} width={400} />
          </div>
          <div className='caption'>
            <Skeleton width={100} height={20} />
            <Skeleton width={`80%`} height={25} />
            <div className="postDetails">
              <Skeleton width={60} height={20} />
            </div>
          </div>
        </div>
      ) : (
        featuredPost && (
          <div className='innerWrapper'>
            <div className='image'>
              <Link to={`/${categorySlug}/${featuredPost.slug}`}>
                {featuredImage && (
                  <img src={featuredImage} alt={featuredPost.title.rendered} />
                )}
              </Link>
            </div>
            <div className='caption'>
              {categoryName && <span className="category categoryBadge">{categoryName}</span>}
              <Link to={`/${categorySlug}/${featuredPost.slug}`}>
                <h3 className='crimson-text-regular'>{featuredPost.title.rendered}</h3>
              </Link>
              {/* <div className="postDetails">
                {formattedDate && <span className="date">{formattedDate}</span>}
              </div> */}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default FeaturedPosts;
