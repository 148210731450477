import React, { useState } from 'react';
import './Footer.css';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('/php/server.php', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Error subscribing');
      }

      setEmail(''); 
      setSuccessMessage('Successfully subscribed!'); 
      setError(null);
    } catch (error) {
      setError(error.message); 
      setSuccessMessage('');
    }
  };

  return (
    <footer className="footer">
      <div className='container'>
        <div className="footer-content">
          <div className="footer-section newsletters">
            <h3>Subscribe to our Newsletter</h3>
            <form onSubmit={handleSubmit}>
              <div className="newsletter-form">
                <input
                  type="email"
                  placeholder="Enter your email"
                  aria-label="Email address"
                  className="form-control"
                  value={email}
                  onChange={handleEmailChange} 
                  required
                />
                <button type="submit" className="btn btn-primary">
                  Subscribe
                </button>
              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              <small>By clicking on 'Sign me up' you agree to our Terms of Use & Privacy Policy.</small>
            </form>
          </div>

          <div className="footer-section social">
            <p>&copy; 2024 SmartMoney. All Rights Reserved.</p>
            <div style={{ display: 'flex', gap: '30px' }}>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} color="#4267B2" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={30} color="#1DA1F2" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} color="#C13584" />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} color="#0077B5" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
