import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './PostDetail.css'; // You can style it here
import Breadcrumbs from './components/Breadcrumbs';
import StickySidebar from './components/StickySidebar';
import { ThreeDots } from 'react-loader-spinner';
import Newsletter from './components/Newsletter';
const PostDetail = () => {
  const { categorySlug, slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.body.style.overflow = 'visible';
  
    const fetchPostDetail = async () => {
      try {
        // Fetch post
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/posts?slug=${slug}`);
        if (!response.ok) {
          throw new Error('Failed to fetch post detail');
        }
        const postsData = await response.json();
  
        if (postsData.length > 0) {
          const postData = postsData[0];
  
          // Fetch category details using categorySlug
          const categoryResponse = await fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/categories?slug=${categorySlug}`);
          const categoryData = await categoryResponse.json();
  
          // Continue with your existing media fetching logic
          if (postData.featured_media) {
            const mediaResponse = await fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/media/${postData.featured_media}`);
            if (mediaResponse.ok) {
              const mediaData = await mediaResponse.json();
              postData.imageUrl = mediaData.source_url; 
            }
          }
  
          setPost({
            ...postData, 
            categoryName: categoryData[0]?.name,
            categorySlug: categorySlug
          });
        } else {
          throw new Error('No post found with this slug');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPostDetail();
  }, [slug, categorySlug]);

  if (loading) {
    return (
      <div className="loading-container">
        <ThreeDots color="#000" height={80} width={80} />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!post) {
    return <p>No post found.</p>;
  }

  return (
<div className='pDetail'>
{/* <div className='container'>
        <div className='row'>
          <div className='column'>
      <Breadcrumbs />
      </div>
      </div>
      </div> */}
      <div className="container">
        <div className="row">
        {/* <div className="sidebar-column">
            <StickySidebar />
          </div> */}
          <div className="centered-column">  
          <h1>{post.title.rendered}</h1>    
            {post.imageUrl && ( 
        <img
          src={post.imageUrl}
          alt={post.title.rendered}
          style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
        />
      )}
            <span className='date'><strong>Updated</strong> {new Date(post.date).toLocaleDateString()}</span>

     
      <div className='detail' dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
    </div>
<div className='right-column'>
  <div className='sticky-sidebar autowidth'>
    <Newsletter clientKey='nm7sm9rndtagz2k4ayk2tbxgih0rru83' tag={post.categorySlug}  />
</div>
</div>
    

    </div>
    </div>
    </div>
  );
};

export default PostDetail;
