import React, { useState } from 'react';
import './Newsletter.css';

const Newsletter = ({ tag = null }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('/php/server.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, tag }),
      });

      if (!response.ok) {
        throw new Error('Error subscribing');
      }

      setEmail('');
      setSuccessMessage('Successfully subscribed!');
      setError(null);
    } catch (error) {
      setError(error.message);
      setSuccessMessage('');
    }
  };

  return (
    <div className='newsletter'>
      <h2>Take control of your finances!</h2>
      <p>Get advice on making, saving, and managing your money sent directly to your inbox daily.</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <button type="submit">Sign Me Up!</button>
        <small>By clicking on 'SIGN ME UP', you agree to our<br />Terms of Use & Privacy Policy</small>
      </form>
    </div>
  );
};

export default Newsletter;
