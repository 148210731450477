import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './TPosts.css';
import { Link } from 'react-router-dom';

const timeAgo = (date) => {
  const now = new Date();
  const postDate = new Date(date);
  const secondsAgo = Math.floor((now - postDate) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
  };

  for (const [unit, secondsInUnit] of Object.entries(intervals)) {
    const interval = Math.floor(secondsAgo / secondsInUnit);
    if (interval >= 1) {
      return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
    }
  }
  return 'Just now';
};

const EditorsPosts = () => {
  const [editorposts, setEditorsPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/posts?categories=3&per_page=3&_orderby=date&_order=desc`)
    .then(response => response.json())
      .then(data => {
        const fetchPostDetails = data.map(post => {
          if (post.featured_media) {
            return fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/media/${post.featured_media}`)
              .then(response => response.json())
              .then(mediaData => {
                return fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/categories/${post.categories[0]}`)
                  .then(categoryResponse => categoryResponse.json())
                  .then(categoryData => {
                    return { ...post, featuredImage: mediaData.source_url, categorySlug: categoryData.slug };
                  });
              });
          } else {
            return fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/categories/${post.categories[0]}`)
              .then(categoryResponse => categoryResponse.json())
              .then(categoryData => {
                return { ...post, featuredImage: null, categorySlug: categoryData.slug };
              });
          }
        });

        Promise.all(fetchPostDetails).then(postsWithImagesAndCategories => {
          setEditorsPosts(postsWithImagesAndCategories);
          setLoading(false); 
        });
      })
      .catch(error => {
        console.error('Error fetching editor posts:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className='tPosts'>
      <h2>Editor's Posts</h2>
      <ul>
        {loading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <li key={index} className='postItem'>
              <div className='image'>
                <Skeleton height={150} />
              </div>
              <div className='caption'>
                <Skeleton width={200} height={25} /> 
                <Skeleton width={100} /> 
              </div>
            </li>
          ))
        ) : (
          editorposts.map(post => (
            <li key={post.id} className='postItem'>
              <div className='image'>
                <Link to={`/${post.categorySlug}/${post.slug}`}>
                  {post.featuredImage && (
                    <img
                      src={post.featuredImage}
                      alt={post.title.rendered}
                    />
                  )}
                </Link>
              </div>
              <div className='caption'>
                <Link to={`/${post.categorySlug}/${post.slug}`}>
                  <h3>{post.title.rendered}</h3>
                </Link>
                {/* <div className="postDetails">
                  <span className="date">{timeAgo(post.date)}</span>
                </div> */}
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default EditorsPosts;
