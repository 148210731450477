import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CategoryTrendingPost from './components/CategoryTrendingPost';
import CategoryPosts from './components/CategoryPosts';
import Breadcrumbs from './components/Breadcrumbs';

const CategoryDetail = () => {
  const { slug } = useParams();
  const [posts, setPosts] = useState([]);
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [categoryId, setCategoryId] = useState(null);

  const fetchFeaturedMedia = async (mediaId) => {
    try {
      const mediaResponse = await fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/media/${mediaId}`);
      const mediaData = await mediaResponse.json();
      return mediaData.source_url;
    } catch (error) {
      console.error('Error fetching featured media:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const categoryResponse = await fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/categories?slug=${slug}`);
        const categoryData = await categoryResponse.json();

        if (categoryData.length > 0) {
          setCategoryName(categoryData[0].name);
          setCategoryId(categoryData[0].id);

          const postsResponse = await fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/posts?categories=${categoryData[0].id}`);
          const postsData = await postsResponse.json();

          const updatedPosts = await Promise.all(
            postsData.map(async (post) => {
              const imageUrl = post.featured_media ? await fetchFeaturedMedia(post.featured_media) : null;
              return { ...post, imageUrl, categorySlug: slug }; 
            })
          );
          setPosts(updatedPosts);

          const featuredPostsResponse = await fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/posts?categories=${categoryData[0].id}&per_page=2`);
          const featuredPostsData = await featuredPostsResponse.json();

          const updatedFeaturedPosts = await Promise.all(
            featuredPostsData.map(async (post) => {
              const imageUrl = post.featured_media ? await fetchFeaturedMedia(post.featured_media) : null;
              return { ...post, imageUrl, categorySlug: slug }; 
            })
          );
          setFeaturedPosts(updatedFeaturedPosts);
        }
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };

    fetchCategoryData();
  }, [slug]);

  return (
    <div className='categoryPosts'>
      <div className='container'>
        <div className='row'>
          <div className='column'>
      <Breadcrumbs />
      </div>
      </div>
      </div>
      {featuredPosts.length > 0 && (
        <CategoryTrendingPost featuredPost={featuredPosts[0]} />
      )}
      <CategoryPosts posts={posts} />
    </div>
  );
};

export default CategoryDetail;
