import React from 'react';
import FeaturedPosts from './components/FeaturedPosts';
import TrendingPosts from './components/TrendingPosts';
import EditorsPosts from './components/EditorsPosts';
import RecentPost from './components/RecentPost';
import Newsletter from './components/Newsletter';
import './Home.css';


const Home = () => {
  return (
    <div className='container'>
      <div className="row">
        <div className="column-3">
          <FeaturedPosts />
        </div>
        <div className="column compact">
            <Newsletter clientKey='nm7sm9rndtagz2k4ayk2tbxgih0rru83' />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <TrendingPosts />
        </div>
        <div className="column">
          <EditorsPosts />
        </div>
      </div>
      <div className="row">
        <div className="column">
            <RecentPost categoryId={5} color='green' />
        </div>
        <div className="column borderCol">
            <RecentPost categoryId={6} color='lightgreen' />
        </div>
        <div className="column">
            <RecentPost categoryId={7} color='brown' />
        </div>
      </div>
      
      {/* <div className="row">
        <div className="column">
            <RecentPost categoryId={3} />
        </div>
        <div className="column">
            <RecentPost categoryId={3} />
        </div>
      </div> */}
    </div>
  );
};

export default Home;
