import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './MainMenu.css';

const MainMenu = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const excludeCategories = [1, 3, 4];

    fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/categories?exclude=${excludeCategories.join(',')}`)
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  const handleCategoryClick = (slug) => {
    navigate(`/${slug}`);
  };

  return (
      <ul className='mainMenu'>
        {categories.map(category => (
          <li key={category.id}>
            <a onClick={() => handleCategoryClick(category.slug)}
              className={location.pathname === `/${category.slug}` ? 'active' : ''}>
              {category.name}
            </a>
          </li>
        ))}
      </ul>
  );
};

export default MainMenu;
