import React from 'react';
import './StickySidebar.css';

const StickySidebar = () => {
  return (
    <div className="sticky-sidebar">
      <h2>Social Media</h2>
      
    </div>
  );
};

export default StickySidebar;
