import React, { useEffect, useState } from 'react';
import './UPosts.css';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton CSS

// Helper function to format the date into "ago" format
const timeAgo = (date) => {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) return `${interval} years ago`;

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return `${interval} months ago`;

  interval = Math.floor(seconds / 86400);
  if (interval > 1) return `${interval} days ago`;

  interval = Math.floor(seconds / 3600);
  if (interval > 1) return `${interval} hours ago`;

  interval = Math.floor(seconds / 60);
  if (interval > 1) return `${interval} minutes ago`;

  return `${Math.floor(seconds)} seconds ago`;
};

const RecentPost = ({ categoryId, color }) => {
  const [recentPost, setRecentPost] = useState(null);
  const [nextRecentPosts, setNextRecentPosts] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [categorySlug, setCategorySlug] = useState('');
  const [featuredImageUrls, setFeaturedImageUrls] = useState({}); // State to store featured image URLs
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    setLoading(true); // Start loading
    fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/posts?categories=${categoryId}&per_page=3`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setRecentPost(data[0]);
          setNextRecentPosts(data.slice(1, 3));

          // Fetch featured images for all posts
          data.forEach((post) => {
            if (post.featured_media) {
              fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/media/${post.featured_media}`)
                .then(response => response.json())
                .then(mediaData => {
                  setFeaturedImageUrls(prevState => ({
                    ...prevState,
                    [post.id]: mediaData.source_url, // Store image URL by post ID
                  }));
                })
                .catch(error => console.error('Error fetching media:', error));
            }
          });
        }
        setLoading(false); // End loading
      })
      .catch(error => {
        console.error('Error fetching recent posts:', error);
        setLoading(false); // End loading even on error
      });
  }, [categoryId]);

  useEffect(() => {
    if (categoryId) {
      fetch(`${process.env.REACT_APP_BASE_URL}wp-json/wp/v2/categories/${categoryId}`)
        .then(response => response.json())
        .then(data => {
          setCategoryName(data.name);
          setCategorySlug(data.slug); // Fetch and set the category slug
        })
        .catch(error => console.error('Error fetching category name:', error));
    }
  }, [categoryId]);

  return (
    <div className={`uPosts ${color}`}>
      <h2>{loading ? <Skeleton width={150} /> : categoryName}</h2>
      <div className='lastRecentPost'>
        {loading ? (
          <Skeleton height={450} width={300} /> // Skeleton for loading state
        ) : recentPost ? (
          <div className='innerWrapper'>
            {featuredImageUrls[recentPost.id] && (
              <div className='image'>
                <Link to={`/${categorySlug}/${recentPost.slug}`}>
                  <img 
                    src={featuredImageUrls[recentPost.id]} 
                    alt={recentPost.title.rendered}
                    className="featured-image"
                  />
                </Link>
              </div>
            )}
            <div className='caption'>
              <Link to={`/${categorySlug}/${recentPost.slug}`}>
                <h3>{recentPost.title.rendered}</h3>
              </Link>
              {/* <div className='postDetails'>
                <span className='date'>{timeAgo(recentPost.date)}</span>
              </div> */}
            </div>
          </div>
        ) : (
          <p>No recent post found.</p>
        )}
      </div>

      <div className='lastRecentPosts'>
        {loading ? (
          <>
            <Skeleton height={150} />
            <Skeleton height={150} />
          </>
        ) : nextRecentPosts.length > 0 ? (
          nextRecentPosts.map((post) => (
            <div className='innerWrapper' key={post.id}>
              {featuredImageUrls[post.id] && (
                <div className='image'>
                  <Link to={`/${categorySlug}/${post.slug}`}>
                    <img 
                      src={featuredImageUrls[post.id]} 
                      alt={post.title.rendered}
                      className="featured-image"
                    />
                  </Link>
                </div>
              )}
              <div className='caption'>
                <Link to={`/${categorySlug}/${post.slug}`}>
                  <h3>{post.title.rendered}</h3>
                </Link>
                <div className='postDetails'>
                  <span className='date'>{timeAgo(post.date)}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default RecentPost;
