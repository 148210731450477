import React from 'react';
import { Link } from 'react-router-dom';
import './CategoryPosts.css';

const CategoryPosts = ({ posts, categorySlug }) => {
  return (
    <div className='cPosts'>
      <div className="container">
      <h2>More Posts</h2>
        <div className="row grid">
          {posts.length > 0 ? (
            posts.map((post) => (
              <div className="column-3 categGrid" key={post.id}>
                <div className='innerWrapper'>
                  {post.imageUrl && (
                    <div className='image'>
                      <Link to={`/${categorySlug}/${post.slug}`}>
                        <img
                          src={post.imageUrl}
                          alt={post.title.rendered}
                          style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                        />
                      </Link>
                    </div>
                  )}
                  <div className='caption'>
                    <Link to={`/${categorySlug}/${post.slug}`}>
                      <h3>{post.title.rendered}</h3>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No posts found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryPosts;
