import React from 'react';
import './CategoryTrendingPost.css';
import { Link } from 'react-router-dom';

const CategoryTrendingPost = ({ featuredPost }) => {
  return (
    <div className='cPost'>
      <div className="container">
        <div className="row">
          <div className="column-3">
            {featuredPost ? (
              <>
        <div className='innerWrapper' key={featuredPost.id}>
        {featuredPost.imageUrl && (
            <div className='image'>
              <Link to={`/${featuredPost.categorySlug}/${featuredPost.slug}`}>
                    <img
                      src={featuredPost.imageUrl}
                      alt={featuredPost.title.rendered}
                    />
                    </Link>
                    </div>
                  )}
                <div className='caption'>
                <Link to={`/${featuredPost.categorySlug}/${featuredPost.slug}`}><h3>{featuredPost.title.rendered}</h3></Link>
                </div>
                </div>
              </>
            ) : (
              <p>No featured post found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryTrendingPost;
